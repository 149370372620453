import { NewJPButton } from '../GeneralComponents';
import { SectionContent } from '../OldGeneralComponents';
import ArcadeDemo from '../../components/Shared/ArcadeDemo';
import ExportArrow from '../../assets/images/seo/exporarrow.svg';
import GenerateArrow from '../../assets/images/seo/rightarrowmarketing.svg';
import Icon, { IconType } from '../Shared/Icon';
import Image from 'next/image';
import Modal from '../Modals/BaseModal';
import React, { useState } from 'react';
import router from 'next/router';

const MarketingConversion = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <SectionContent maxWidth={'1024px'}>
      <div className="relative flex flex-col justify-center px-3 pt-[60px]">
        <Modal
          isVisible={isModalOpen}
          className="flex flex-col"
          closeModal={() => setIsModalOpen(false)}
        >
          <button
            className="absolute right-4 top-4 z-10 cursor-pointer"
            onClick={() => setIsModalOpen(false)}
          >
            <Icon type={IconType.CrossLight} size={24} />
          </button>
          <ArcadeDemo className="lg:h-[500px] xl:h-[500px]" />
        </Modal>
        <h1 className="mx-auto mb-2 text-center text-[30px] font-500 leading-[44px] tracking-[-0.72px] sm:text-[32px] md:text-[34px] lg:text-[32px]">
          Skip the 6-hour Editing. Record, Upload, Done.
        </h1>
        <h2 className="mx-auto mb-[48px] text-center text-base font-normal leading-[32px] text-grey-600 sm:text-[20px] lg:text-[20px]">
          Video marketing shouldn’t take all day. Save time to focus on growing
          your business.
        </h2>
        <div className="mx-auto grid grid-cols-1 items-center sm:grid-cols-5 lg:grid-cols-5">
          <img
            className="mx-auto w-4/5 overflow-hidden rounded-t-xl lg:w-auto"
            src={
              'https://d1ncc9q91dserr.cloudfront.net/assets/images/seo/marketing_conversion_recording.svg'
            }
            alt={'footer image - try jupitrr for free'}
          />
          <Image
            className="mx-auto mb-[50px] mt-[50px] w-full max-w-[100px] rotate-90 overflow-hidden rounded-t-xl md:rotate-0 lg:mb-0 lg:mr-[80px] lg:mt-0 lg:rotate-0"
            src={GenerateArrow}
            alt={'footer image - try jupitrr for free'}
          />
          <img
            className="mx-auto overflow-hidden rounded-t-xl lg:ml-[-82px] lg:max-w-[350px]"
            src={
              'https://d1ncc9q91dserr.cloudfront.net/assets/images/seo/autogeneratemarketing.svg'
            }
            alt={'footer image - try jupitrr for free'}
          />
          <Image
            className="relative mx-auto mb-[50px] mt-[50px] w-full max-w-[100px] rotate-90 overflow-hidden rounded-t-xl md:rotate-0 lg:rotate-0"
            src={ExportArrow}
            alt={'footer image - try jupitrr for free'}
          />
          <img
            className="mx-auto w-full overflow-hidden rounded-t-xl lg:w-full"
            src={
              'https://d1ncc9q91dserr.cloudfront.net/assets/images/seo/yourpost.svg'
            }
            alt={'footer image - try jupitrr for free'}
          />
        </div>
        <div className="mt-[48px] flex h-12 justify-center space-x-4">
          <NewJPButton
            colorType="transparent"
            text={'Watch Demo'}
            width={'fit'}
            size="sm"
            onClick={() => setIsModalOpen(true)}
            className="justify-center border border-solid !border-gray-300 text-grey-600"
          />

          <NewJPButton
            text={'Try it with your video'}
            width={'fit'}
            size="sm"
            className="justify-center"
            onClick={() => router.push('/create-slideshow')}
          />
        </div>
      </div>
    </SectionContent>
  );
};

export default MarketingConversion;
